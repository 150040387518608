<template>
  <div class="admin-cars">
    <title-bar title="Cars" />
    <table class="admin-cars-list">
        <thead>
            <th>Car</th>
            <th>Courier</th>
        </thead>
      <tr v-for="(car, index) of cars" :key="index" class="admin-cars-list-item">
        <td class="admin-cars-list-item-car"> {{ car.name }}</td>
        <td class="admin-cars-list-item-courier">
          <div class="main-dropdown">
            <v-select
              v-model="car.courier"
              :options="couriers"
              class="custom-select"
              :clearable="true"
              :searchable="true"
              @input="updateCourier(car)"
            >
              <template slot="selected-option" slot-scope="option">
                <div>
                  <img v-if="option.img" class="select-photo" :src="option.img" alt="" />
                  <span>{{ option.label }}</span>
                </div>
              </template>
              <template #option="{ label, img }">
                <img v-if="img" class="select-photo" :src="img" alt="" />
                <span> {{ label }}</span>
              </template>
            </v-select>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import Toast from '@/components/Toast.vue'

export default {
  components: {
    TitleBar,
  },
  created () {
    this.$Cars.getCollection({ params: { pagination: false } }, 'car_list').then(response => {
      this.cars = response.data['hydra:member']
      this.cars.forEach(car => {
        if(car.courier){
          car.courier = { label: `${car.courier.givenName} ${car.courier.familyName}`, id: car.courier['@id']  }
        }
      })
    })

    this.$Users.getCollection({ params: { pagination: false, role: 'ROLE_COURIER', enabled: true } }, 'courier_list').then(response => {
      response.data['hydra:member'].forEach(courier => {
        this.couriers.push({ label: `${courier.givenName} ${courier.familyName}`, id: courier['@id'], img: courier.fileUrl })
      })
    })
  },
  data() {
    return {
      apiUrl: this.$store.state.apiUrl,
      cars: [],
      couriers: []
    }
  },
  methods: {
    updateCourier(car) {
      const body = {
        courier: (car.courier) ? car.courier.id : null,
      }
      this.$Cars.updateResource({ id: car.id, body }).then(response => {
        if (response.status === 200) {
          this.$helper.showToast(this.$toast, Toast, "Car's courier updated", '', 'success')
        }
      }).catch(error => {
          if (error.response) {
            this.$helper.showToast(
              this.$toast,
              Toast,
              error.response.data['hydra:title'],
              error.response.data['hydra:description'],
              'danger',
            )
          }
        })
    }
  }
}
</script>
